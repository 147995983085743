// Estilos para home.blade.php

div#backimage {

			// background-color: yellow;
			background-image: url(../../public/img/glenn-carstens-peters-203007-unsplash.jpg);
			background-size: cover;
			width: 100%;
			height: 90%;
			margin-bottom: 40%; //Rellena el resto de la página hasta el final
			padding-left: 5%;
			padding-right: 50%;
			padding-top: 3%;

			div.textbox{
				
				@extend %formato-caja;
				background-color: black;
				border-radius: $curva-bordes;
				opacity: 0.78;

				p{
					opacity: 1;
					color: white;
				}


			}
		}