
// Fonts
// @import url('https://use.typekit.net/vpb7dbj.css');


// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'forms';
@import 'login';
@import 'squares';
@import 'dropdownMenu';
@import 'article';

* {

	@extend %letra-base;
}



h1 {

	@media (max-width: 600px) {
		font-size: 34px
	}
}

h2 {
	@media (max-width: 600px) {
		font-size: 28px
	}
}

h3 {
	@media (max-width: 600px) {
		font-size: 22px
	}
}

h4 {
	@media (max-width: 600px) {
		font-size: 20px
	}
}

h5 {
	@media (max-width: 600px) {
		font-size: 17px
	}
}

body {
	@media (max-width: 600px) {
		font-size: 17px
	}
}


a {	color: white; }


.ifletButton {

   		cursor: pointer;
	    color: white;
	    border: none;
	    background: #1565C0;
	    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
	    border-radius: 10px;
	    color: white;
	    font-weight: 500;
	    font-size: 18px;
	    height: 40px;
	    padding: 0px 24px;
}

a:hover { 

		text-decoration: none;
		color: $color-corporativo;

	}


div#app{

	background-color: $blanco-fondo;

	header{
		
		color: white;
		background-color: $gris-cabecera;

		nav{

			li{

				@extend %enlaces-menu;
				padding: 5px;

			}
		}

		h1{
			a:hover{ color: white; }

			@extend %letra-titulo;
			height: 20%;
			font-size: 5em;
			padding-bottom: 1%;
			padding-top: 1%;


		}



		li{

			list-style: none;
			@extend %enlaces-menu;
		}


	}

	h1.title{

		padding-top: 1%;
		padding-bottom: 1%;
		background-color: white;
		border: solid 0.01px $color-bordes;


	}

	section.content{

		padding: 0;

		@import 'home';

		@import 'blog';

		@import 'portfolio';

		@import 'author';

		@import 'register';

		@import 'verify';


	}

	footer{

		color: white;
		background-color: $gris-pie;
		height: 100px;
		padding: 50px;

	}


}




// .navbar-laravel {
//   background-color: #fff;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
// }
