.formsContainer {
    display: grid;
    grid-template-rows: 1200px 44px;
    grid-template-columns: 1fr;
    transform: rotate(15);

    @media(max-width: 600px) {
        grid-template-rows: 1fr 44px;
    }
}

.formsMagicBackground {
    width: 100%;
    height: 100%;
    background-image: url("../images/BlogBackground.svg"); 
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 1;
    
    display: grid;
    grid-template-rows: 80px 1fr;

    @media(max-width: 600px) {
       background-size: 200%;
       grid-template-rows: 270px 1fr;
    }
}

// Esto se tiene que mover a _register.scss

.registerSection{
    display: grid;
    justify-self: center;
}

.registerCardWrapper {
    width: 570px;
    height: 470px;
    filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.2));
    background: rgba(255, 255, 255, 0.51);
    backdrop-filter: blur(40px);
    border-radius: 30px;
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);

    display: grid;
    grid-template-rows: 140px 1fr;

    @media (max-width: 600px) {
        padding: 20px;
        width: auto;
        height: auto;
        grid-template-rows: auto;
    }
}

.registerCardTitle {
    font-weight: bold;
    font-size: 80px;
    line-height: 95px;
    align-self: center;
    justify-self: center;
    color: #1565C0;

    @media (max-width: 600px) {
        font-size: 50px;
    }
}

.RegisterCardForm {
    display: grid;
    grid-template-rows: repeat(5, 48px) 60px;
    align-items: center;

     @media (max-width: 600px) {
        grid-auto-rows: 75px;
        grid-template-rows: auto;
     }
}

.FormFieldWrapper {
    display: grid;
    grid-template-rows: 30px;
    grid-template-columns: 110px 330px;
    align-items: center;
    justify-self: center;
    column-gap: 16px;

    @media (max-width: 600px) {
        grid-template-rows: auto;
        grid-template-columns: auto;
    }
}

.FormFieldText {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    align-self: center;
    margin-bottom: 0;
}

.FormFieldInput {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    border: 0;
    padding: 0 1rem;
    text-align: center;
}

.RegisterSubmitRow {
    justify-self: end;
    align-self: center;
    display: grid;
    grid-template-columns: 130px 255px;

    // div {
    //     display: block;
    // }

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        justify-self: center;
    }

}


.RegisterCardSubmit {
    background: #1565C0;
    color: white;
    font-weight: 500;
    font-size: 18px;
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    height: 40px;
    padding: 0px 24px;
    border: 0;

    justify-self: start;
    align-self: end;
}

