.loginGroup {
	display: grid;

    a {
        padding: 0 10px;
    }

    a:hover{
        color: white;
    }

    @media (max-width: 600px) {
        display: grid;
        grid-template-columns: 1fr;
        justify-self: center;
        padding: 0;
    }
}

.loginDropDown {

	justify-self: center;
	align-self: end;

	position: relative;
    display: inline-block;

	
	button {
		display: none;
	    
	    color: white;
	    border: none;
	    background: none;
	    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
	    border-radius: 10px;
	    font-weight: 500;
	    font-size: 18px;
	    height: 40px;
	    width: 108px;
	    align-items: center;
	    justify-items: center;
	}

	button.user-name {
		display: block;

   		cursor: pointer;
	    color: white;
	    border: none;
	    background: #1565C0;
	    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
	    border-radius: 10px;
	    color: white;
	    font-weight: 500;
	    font-size: 18px;
	    height: 40px;
	    padding: 0px 24px;
   }
	
}

.loginDropDown:hover {
	button {
		display: block;
	}
}

form#panel-form form#logout-form {
	display: none;
}

