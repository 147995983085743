//Estilos para login.blade.php

div.register-container{

	div.card{

		padding-bottom: 30%;
		padding-top: 5%;
	}

}