//Estilos para login.blade.php
.LoginSection{
	display: grid;
	justify-self: center;
}
	
.LoginCardWrapper {
	width: 570px;
	height: 410px;
	filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.2));
	background: rgba(255, 255, 255, 0.51);
	backdrop-filter: blur(40px);
	border-radius: 30px;
	box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);

	display: grid;
	grid-template-rows: 160px 1fr;

	@media (max-width: 600px) {
		width: auto;
		height: auto;
		grid-template-rows: auto;
	}
        
}

.LoginCardTitle {
	font-weight: bold;
	font-size: 80px;
	line-height: 95px;
	align-self: center;
	justify-self: center;
	color: #1565C0;

	@media (max-width: 600px) {
		font-size: 50px;
	}
}

.LoginCardForm {
	display: grid;
	grid-template-rows: 48px 48px 1fr;
	justify-items: center;

	@media (max-width: 600px) {
		grid-template-rows: auto;
	}
}

.FormFieldWrapper {	
	display: grid;
	grid-template-rows: 30px;
	grid-template-columns: 110px 330px;
	align-items: center;
	column-gap: 16px; 

	@media (max-width: 600px) {
		grid-template-columns: 1fr;
		justify-items: center;
		padding: 4px;
	}
}


.FormFieldText {
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	align-self: center;
	margin-bottom: 0;
}

.FormFieldInput {	
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 5px;
	border: 0;
	padding: 0 1rem;
	text-align: center;
}

.LoginCardSubmit {
	background: #1565C0;
	color: white;
	font-weight: 500;
	font-size: 18px;
	box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	height: 40px;
	padding: 0px 24px;
	border: 0;

	justify-self: center;
	align-self: center;
}
.LoginCardButtons {
	padding-left: 50px;

	@media (max-width: 600px) {
		padding: 20px;
	}

}

.LoginCardButtonsRow {
	display: grid;
	grid-template-columns: 100px 25px 130px;
	grid-auto-rows: 50px;
	align-items: center;
}
	
.LoginCardCheckBox {
		justify-self: center;
		align-self: center;
	}

.LoginCardCheckBoxLabel {
	color: black;
	font-weight: 500;
	font-size: 18px;
	justify-self: start;
	align-self: center;
	margin: 0;
	padding: 0;
}

.LoginCardButtonsRow2 {
	display: grid;
	grid-template-columns: 250px;
	grid-auto-rows: 20px;
	align-items: end;
}

.LoginCardForgot {    
	color: #1565C0;
	font-size: 13px;
	font-weight: 500;
	line-height: 130%;
}

.LoginCardRegister {	
	color: black;
	font-size: 13px;
	font-weight: 500;
	line-height: 130%;

	& a {
		color: #1565C0;
	}
}

.LoginCardErrors {
	div{
		display: block;
	}
}
