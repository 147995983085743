


//Estilos para blog.blade.php

		div.articulosbox{

			div.articulobox {

				@extend %formato-caja;
				
				div.artcile-title {	

					background-color: $gris-cabecera;
					color: white;
					border-radius: $curva-bordes $curva-bordes 0 0;
					padding: 20px;
				

				// a:hover { 

				// 		text-decoration: none;
				// 		color: $color-corporativo;
				// 	}

				}

				div.artcile-fecha{

					background-color: $color-corporativo;
					color: white;
					padding: 2px 15px;

				}

				div.article-body{

					background-color: white;
					border-radius: 0 0 $curva-bordes $curva-bordes;
					color: $gris-cabecera;
					padding: 20px;
					@extend %bordes-caja;
					@extend %letra-texto;

					a{ color: silver; }
					a:hover{ color: $color-corporativo; }

				}



			}
		
		
		}

		div.article{ 

			background-color: gray;
			padding: 1%;


		}