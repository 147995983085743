.panelContainer{

	display: grid;
	grid-template-rows: 80px 100px 280px 1fr;
	grid-template-columns: 1fr;

	
}

.panelMenuBar{
    padding: 20px 30px;
    z-index: 100;
    display: grid;
    grid-template-columns: 200px 1fr 200px;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        justify-items: center;
    }
}


.panelTitle {
	color: white;
	font-size: 80px;
	font-weight: 800;
    align-self: center;
    justify-self: center;
}

.formContainer {
	width: 590px;
	height: 240px;
	background: rgba(255, 255, 255, 0.2);
	/* Note: backdrop-filter has minimal browser support */
	backdrop-filter: blur(40px);
	border-radius: 30px;
	align-self: center;
	justify-self: center;

	display: grid;
	grid-template-rows: 2fr 3fr;

.formTitle {
	color: white;
	font-weight: bold;
	font-size: 24px;
	line-height: 41px;
	justify-self: center;
	align-self: center;

}

.panelForm {
	display: grid;
	justify-self: center;

	.editGroup {

		width:425px;
		height: 30px;
		display: grid;
		grid-template-columns: 100px 350px;
		align-items: start;
		justify-items: start;

		.editGroupTitle {
			color: #1565C0;
			font-weight: 500;
			font-size: 20px;

		}
	}

	.panelSubmit {
		background: #1565C0;
	    color: white;
	    font-weight: 500;
	    font-size: 18px;
	    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
	    border-radius: 10px;
	    height: 40px;
	    padding: 0px 24px;
	    border: 0;

	    justify-self: center;
	    align-self: start;
	}

}

	.cardsContainer {
		min-height: 1000px;
		display: grid;
	}
}