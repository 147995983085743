// Estilos para author.blade.php




div.bio{

	//z-index: 5;
	background-color: white;
	margin: 5% 10% 5% 10%;
	padding: 50px 50px 50px 50px;
	border-radius: $curva-bordes;
	@extend %bordes-caja;
	@extend %letra-texto;

		div.box {

			// position: absolute;
			// 	height: 200px;
			// 	width: 30%;
			margin-bottom: 10px;



			div.picture{
				
				// position: absolute;
				height: 150px;
				width: 150px;
				background-color: white;
				margin-bottom: 10px;
				// padding: 5px;
				// left: 40%;
				// z-index: 10;
				//La siguiente linea no funciona en el servidor en producción ya que la carpeta public esta servida mediante enlaces simbólicos p
				// background-image: url(../../public/img/ProfilePictureCompress.jpg);
				background-image: url(/var/www/iflet/public/img/ProfilePictureCompress.jpg); 
				background-size: cover;

					// img{
					// 	 width: 145px; /* Width of new image */
					// 	 height: 145px;
					// 	//La siguiente linea no funciona en el servidor en producción ya que la carpeta public esta servida mediante enlaces simbólicos
					// 	background-image: url(../../public/img/ProfilePictureCompress.jpg); 
					// 	// background: url(/var/www/iflet/public/img/ProfilePictureCompress.jpg) no-repeat;
						 
					// }
			}
			div.leyenda{
				p{
					margin-bottom: 0
				}
			}
			// div.container{

			// }
		}
}
