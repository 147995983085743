
// Body
// $body-bg: #f8fafc;

// // Typography
// $font-family-sans-serif: "Nunito", sans-serif;
// $font-size-base: 0.9rem;
// $line-height-base: 1.6;

// $raleway-bold: 	font-family: raleway, sans-serif,
// 			 	font-weight: 700,
// 			 	font-style: normal;
// $ralewat-light: font-family: raleway, sans-serif,
// 				font-weight: 300,
// 				font-style: normal;

 
$font-family-raleway: "raleway", sans-serif;


// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

//Custom colors
$gris-cabecera: rgb(27, 29, 38);
$gris-pie: rgb(63, 67, 88);
// $color-corporativo: rgb(108, 143, 0); Verde
$color-corporativo: #3490dc; //Azul
$blanco-fondo: rgb(250, 250, 250);
$color-bordes: rgb(225, 225, 225);
$curva-bordes: 3px;


// Media query limits
$mobile-portrait-min: 320px;
$mobile-potrait-max: 480px;
$tablet-portrait-min: 481px;
$tablet-potrait-max: 768px;
$laptop-portrait-min: 769px;
$laptop-potrait-max: 1024px;
$desktop-portrait-min: 1025px;
$desktop-potrait-max: 1200px;



%bordes-caja{

	border: solid 0.01px $color-bordes;

}


%letra-texto{

	font-family: $font-family-raleway;
	font-weight: 300;
 	font-style: normal;


}

%letra-base{
	
	font-family: $font-family-raleway;
	font-size: 1em;
	line-height: 1.6;

}

%letra-titulo{

	font-family: $font-family-raleway;
	font-weight: 700;
 	font-style: normal;
 	font-size: 2em;

}

%enlaces-menu{

	font-weight: 100;
	text-align: center;
}

%formato-caja{

	padding: 20px;
}