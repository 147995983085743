
.articleContainer {
	display: grid;
	width: 100%;

	


		div#MenuBar {
			height: 80px;

			@media (max-width: 600px) {
		    	height: 270px;
		    	
		    }

		}

		.articleTitle {
			max-width: 1020px; 
			color: white;
			font-size: 60px;
			font-weight: 800;
		    align-self: center;
		    justify-self: center;
		    text-align: center;
		    padding: 30px;

		    @media (max-width: 1020px) {
		    	max-width: initial; 
		    }

		    @media (max-width: 600px) {
		    	font-size: 30px;
		    	max-width: 100%;
		    	
		    }
		}


		.article {


			max-width: 1020px; 
			display: grid;
			justify-self: center;

			 @media (max-width: 1020px) {
		    	max-width: initial;
		    	padding: 0 20px;
		    }



			.articleImageContainer {
			
				display: grid;
				justify-self: center;
				grid-template-columns: 1fr;

				img {
						width: 100%;
						padding: 20px;
					    background: rgba(255, 255, 255, 0.2);
	    				backdrop-filter: blur(40px);
				}

			}

			 .articleVideoContainer {

			 	display: grid;

			 	iframe {
			 		width: 100% !important;
			 		justify-self: center;
					padding: 20px;
					background: rgba(255, 255, 255, 0.2);
	    			backdrop-filter: blur(40px);
			 	}
			 }


			.social {
				padding: 10px 20px;
				display: grid;
				grid-template-columns: 90px 90px 90px;
				align-items: center;
				justify-items: center;
			}

			.articleBox {


				.articleBoxHeader {
					display: grid;
					grid-template-columns: 1fr 1fr;
					background: #1565C0;
					color: white;
					padding: 10px 20px;


					@media (max-width: 600px) {
						grid-template-columns: 1fr;
						justify-items: start;
					}

					.articleBoxHeaderData {}

					.articleBoxHeaderAuthor {

						@media (max-width: 600px) {
							justify-self: end;
						}


						.authorCard {
							display: grid;
							grid-template-columns: 1fr 60px;
							align-items: center;
							justify-items: center;

							@media (max-width: 600px) {
								justify-content: start;
								padding: 20px;
								justify-items: start;
								}
							}

							.authorImage {
								width: 50px;
								height: 50px;
								background-image: url("/images/edu_image.svg");
								// background-image: url("https://gravatar.com/avatar/0815692b7f41f457794ce8bc89ab8c79?s=400&d=robohash&r=x");
								background-size: contain;
								border-style: solid;
							    border-radius: 50%;
							    border-color: white;
							}

							.authorName {
								color: white;
								font-size: 20px;
								font-weight: 600;
								justify-self: end;
								padding: 0 20px;

							}
						}

					}


				}

				.articleBoxText {
					display: grid;
					padding: 10px 20px;
					background: rgba(21, 101, 192, 0.05);
					backdrop-filter: blur(40px);
					font-weight: 200;
					
					h1, h2, h3, h4 { font-weight: 800; }

					a { color: #1565c0 }

					p {
						line-height: 2.0;
					}
					/* Note: backdrop-filter has minimal browser support */
					// border-radius: 10px;

					 img {
					 	max-width: 100%  !important;
						height: auto   !important;
					 }

					pre { 
						  overflow: scroll;
						  max-width: 100%;
						  word-break: break-all;
						  background-color: white;
						  padding: 20px;
						  border-radius: 10px;


						  // white-space: pre-wrap;
						}

					

					h1, h2, h3, h4 {
							font-size: 1.7em;
						 	// white-space: nowrap;
						  // 	overflow: hidden;
						  // 	text-overflow: ellipsis;
					}


				}
			}

			.articleComments {
				display: grid;
				grid-template-columns: 1fr;
				margin: 50px 0;
				padding: 30px;
				background: rgba(0, 0, 0, 0.3);
				backdrop-filter: blur(40px);
				border-radius: 10px;

				.articleCommentsContainer {
					
					display: grid;
					padding: 30px;
					margin-bottom: 20px;
					background: rgba(255, 255, 255, 0.2);
					backdrop-filter: blur(40px);
					border-radius: 10px;
					color: white;

					font-weight: 300;

					.articleCommentsCreatedAt {
						font-size: 13px;
					}
					.aricleCommentsName {
						b {
							font-weight: 500;
						}
					}
					.articleCommentsBody {
						border-radius: 10px;
						margin: 10px 0;
						background-color: white;
						padding: 20px;
						color: black;
						font-weight: 500;
					}

				}

				.articleCommentswarning {
					color: white;

				}

				.articleCommentsForm {

					textarea {
						padding: 20px;
						margin: 20px 0;
						width: 100%;
						background: rgba(255, 255, 255, 0.8);
						backdrop-filter: blur(40px);
						border-radius: 10px;
						border: none;
						// resize: none;
					}


				}
			}
		}



