
@import 'panel';

.squaresContainer {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    background-color: #f3f7ff;
  
}

.squaresMagicBackground {
    width: 100%;
    height: 100%;
    background-image: url("../images/PortfolioBackground.svg"); 
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 1;
    
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    

    @media(max-width: 600px) {
      background-size: 370%;
      background-position-x: -500px;
    }
}

.squaresMagicFooter {
    width: 100%;
    height: 100%;
    background-image: url("../images/PortfolioDownBackground.svg"); 
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: 1;
    
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

 

    @media(max-width: 600px) {
        background-size: 300%;
    }
}
